import type {
  IStoreSearchBoundingBox,
  IStoreSearchStore,
} from "~/typings/api/";
import storeSearchApi from "~/api/storeSearchApi";

export default () => {
  const mapBounds = ref<IStoreSearchBoundingBox | null>(null);
  const shopSearchQuery = ref("");
  const listLoadingToken = ref<number | null>(null);
  const mapLoading = ref(true);
  const targetShopRef = ref<IStoreSearchStore | undefined>();
  const storesListRef = ref<IStoreSearchStore[]>([]);

  const { requestAddressSearch, requestGeoSearch } = storeSearchApi();
  const { public: runtimeConfigPublic } = useRuntimeConfig();
  const { selectedStore } = storeToRefs(useStoresStore());

  let abortController: AbortController | null = null;

  const storeTypes = STORE_ALLOWED_SHOP_TYPES_NUMERIC;

  const yandexMapKey = computed(
    () => runtimeConfigPublic.yandexMapKey as string,
  );
  const selectedStoreLocation = computed(() => {
    if (!selectedStore.value?.coordinates) return STORE_MAP_DEFAULT_CENTER;
    return [selectedStore.value.coordinates.latitude, selectedStore.value.coordinates.longitude];
  });
  const listLoading = computed(() => listLoadingToken.value !== null);
  const fetchData = async (
    func: (signal: AbortSignal) => Promise<IStoreSearchStore[]>,
  ) => {
    const token = Date.now();
    try {
      if (abortController !== null)
        abortController.abort("Отменено новым запросом");
      listLoadingToken.value = token;
      abortController = new AbortController();
      const result = await func(abortController.signal);
      storesListRef.value = result;
    } catch (err) {
      console.error("При попытке запроса магазинов, произошла ошибка", { err });
    } finally {
      if (listLoadingToken.value === token) listLoadingToken.value = null;
      if (mapLoading.value) mapLoading.value = false;
      abortController = null;
    }
  };
  const handleMapBoundsChange = (bb: IStoreSearchBoundingBox | null) => {
    if (!bb || shopSearchQuery.value.length >= STORE_SEARCH_STRING_MIN_LENGTH)
      return;
    return fetchData(async (signal: AbortSignal) => {
      const body = {
        aggs: false,
        geoBoundingBox: bb,
        limit: API_GEO_SEARCH_LIMIT,
        storeTypes,
      };
      const { data } = await requestGeoSearch(body);
      signal.throwIfAborted();
      mapBounds.value = bb;
      return data.value?.stores ?? [];
    });
  };
  const handleSearchQueryChange = (val?: string) => {
    if (val !== undefined) shopSearchQuery.value = val;
    const query = shopSearchQuery.value;
    if (!query.length && mapBounds.value !== null) {
      return handleMapBoundsChange(mapBounds.value);
    }
    if (query.length < STORE_SEARCH_STRING_MIN_LENGTH) return;
    return fetchData(async (signal: AbortSignal) => {
      const body = {
        query,
        storeTypes,
        limit: API_ADDRESS_SEARCH_LIMIT,
      };
      const { data } = await requestAddressSearch(body);
      signal.throwIfAborted();
      return data.value?.stores ?? [];
    });
  };

  return {
    mapBounds,
    targetShopRef,
    shopSearchQuery,
    listLoading,
    mapLoading,
    storesListRef,

    yandexMapKey,
    selectedStore,
    selectedStoreLocation,

    handleMapBoundsChange,
    handleSearchQueryChange,
  };
};
